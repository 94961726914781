<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" :disabled="!store.accessRightsHash.VEHICLE_INSPECTION_EDIT" @click="operation.add.click">添加服务商</en-button>
    </template>

    <flex-box>
      <template #default="{ height }">
        <en-table :data="table.data" :height="height" :loading="table.loading" :method="table.get" pagination :paging="table.paging">
          <en-table-column label="操作" width="200">
            <template #default="{ row }: { row: EnosprayCommonDefinitions['EnosprayCustomerAgencyDto'] }">
              <button-delete :method="table.operation.delete.click" :params="row"> 删除 </button-delete>
            </template>
          </en-table-column>
          <en-table-column label="服务商名称" prop="name">
            <template #default="{ row }: { row: EnosprayCommonDefinitions['EnosprayCustomerAgencyDto'] }">
              <en-button type="primary" text @click="table.name.click(row)">{{ row.name }}</en-button>
            </template>
          </en-table-column>
          <en-table-column label="服务商编码" prop="serialNo"></en-table-column>
          <en-table-column label="用户">
            <template #default="{ row }: { row: EnosprayCommonDefinitions['EnosprayCustomerAgencyDto'] }">{{
              row.users?.map((users) => users.name).join(',')
            }}</template>
          </en-table-column>
          <en-table-column label="创建日期" prop="preparedDatetime">
            <template #default="{ row }: { row: EnosprayCommonDefinitions['EnosprayCustomerAgencyDto'] }">
              {{ formatDate(row.preparedDatetime, true) }}
            </template>
          </en-table-column>
          <en-table-column label="备注" prop="comment">
            <template #default="{ row }: { row: EnosprayCommonDefinitions['EnosprayCustomerAgencyDto'] }">
              {{ row.comment || '无' }}
            </template>
          </en-table-column>
        </en-table>
      </template>
    </flex-box>
  </view-item-setting>

  <en-drawer v-model="detail.visible" :title="detail.form.data.id ? '编辑' : '添加' + '服务商'">
    <en-form :model="detail.form.data" :rules="detail.form.rules" :loading="detail.form.loading" :ref="setRef('detailForm')">
      <en-form-item label="服务商名称" prop="name">
        <en-input v-model="detail.form.data.name"></en-input>
      </en-form-item>
      <en-form-item label="服务商编码" prop="serialNo">
        <en-input v-model="detail.form.data.serialNo"></en-input>
      </en-form-item>
      <en-form-item label="选择用户">
        <select-maintain
          v-model="detail.form.data.users"
          :ajax="{
            action: 'GET /enocloud/common/user',
            params: (params, value) => (params.payload = { name: value })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          multiple
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="备注" prop="comment">
        <en-input v-model="detail.form.data.comment" type="textarea" maxlength="200" show-word-limit></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },

      table: {
        ajax: {
          get: {
            action: 'GET /enospray/common/customer/agency',
            data: 'array',
            loading: true,
            pagination: true
          },
          delete: {
            action: 'DELETE /enospray/common/customer/agency/:agencyId',
            loading: true
          }
        },
        children: {
          operation: {
            delete: {
              async click(row: EnosprayCommonDefinitions['EnosprayCustomerAgencyDto']) {
                await this.table.delete({ paths: [row.id] })
                return this.table.get()
              }
            }
          },
          name: {
            click(row: EnosprayCommonDefinitions['EnosprayCustomerAgencyDto']) {
              this.detail.form.init()
              this.detail.form.data.id = row.id
              this.detail.form.get()
              this.detail.visible = true
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',

            ajax: {
              get: {
                action: 'GET /enospray/common/customer/agency/:agencyId',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enospray/common/customer/agency',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enospray/common/customer/agency',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            rules: {
              name: [{ required: true, message: '请输入服务商名称', trigger: 'blur' }],
              serialNo: [{ required: true, message: '请输入服务商编码', trigger: 'blur' }],
              users: [{ type: 'array', required: true, message: '请选择用户', trigger: 'blur' }]
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
